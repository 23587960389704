import React from 'react'

import Layout from '../components/layout'
import Wrapper from '../components/wrapper'
import SEO from '../components/SEO'
import Hero from '../components/hero/hero'
import siteConfig from '../../data/siteConfig'

class MyResume extends React.Component {
  render() {
    const title = siteConfig.resumeTitle

    return (
      <Layout location={this.props.location} noCover={true}>
        <SEO title="Resume" />
        <Hero
          heroImg={siteConfig.siteCover}
          title={title}
        />
        <Wrapper>
          <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=https://storage.googleapis.com/mattpenna-dev-gastby-app-content/Resume%20of%20Matt%20Penna%202019%20v1.docx'
                  align='center' width='850px' height='700px' frameBorder='0'>This is an embedded <a target='_blank'
                                                                                       href='http://office.com'>Microsoft
            Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.
          </iframe>
        </Wrapper>
      </Layout>
    )
  }
}

export default MyResume
